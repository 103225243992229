@tailwind base;
@tailwind components;
@tailwind utilities;

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid red; /* Red */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 767px) {
  .filter-buttons {
    display: flex;
    flex-direction: column;
  }

  .filter-buttons button {
    margin-bottom: 0.5rem;
  }

  .filter-buttons input {
    order: 2;
  }
}
